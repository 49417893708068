import React, { Suspense } from 'react'

import { Route, Routes } from 'react-router-dom'

import Navbar from 'components/NavBar'
import Fallback from 'pages/FallBack'
import routes from 'routes'

export default function App (): JSX.Element {
  return (
    <>
      <Navbar />

      <Suspense fallback={<Fallback />}>
      <Routes>
        {routes.map((route) => (
          <Route key={route.path} path={route.path} element={route.page} />
        ))}
      </Routes>
      </Suspense>

    </>
  )
}
