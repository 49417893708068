import React, { lazy } from 'react'

const Home = lazy(async () => await import('pages/Home'))
const Services = lazy(async () => await import('pages/Services'))
const Activities = lazy(async () => await import('pages/Activities'))
const Login = lazy(async () => await import('pages/Login'))
const SignUp = lazy(async () => await import('pages/SignUp'))
const LoggedArea = lazy(async () => await import('pages/LoggedArea'))
const UserData = lazy(async () => await import('pages/UserData'))

const routes = [
  {
    path: '/',
    page: <Home />
  },
  {
    path: '/services',
    page: <Services />
  },
  {
    path: '/activities',
    page: <Activities />
  },
  {
    path: '/login',
    page: <Login />
  },
  {
    path: '/signUp',
    page: <SignUp />
  },
  {
    path: '/loggedArea',
    page: <LoggedArea />
  },
  {
    path: '/userData',
    page: <UserData />
  }
]

export default routes