import React from 'react'

import { Link as RouterLink } from 'react-router-dom'
import {
  Button,
  Container,
  HStack,
  Link,
  chakra,
  useColorModeValue
} from '@chakra-ui/react'

import ColorModeToggle from 'components/ColorModeToggle'

function Navbar (): JSX.Element {
  const bg = useColorModeValue('gray.50', 'gray.900')

  return (
    <chakra.header bg={bg} w="100%">
      <Container maxW="container.md" py={5}>
        <HStack justify="space-between">
          <HStack spacing={10}>
            <Link as={RouterLink} to="/">
              <HStack>
              </HStack>
            </Link>

            <Link as={RouterLink} to="/">
              Início
            </Link>
            <Link as={RouterLink} to="/activities">
              Atividades
            </Link>
            <Link as={RouterLink} to="/services">
              Serviços
            </Link>
          </HStack>
          <HStack>
            <Link as={RouterLink} to="/login">
              <Button
                fontSize={'sm'}
                fontWeight={400}
                variant={'link'}
              >
                Login
              </Button>
            </Link>
            <Link as={RouterLink} to="/signUp">
              <Button
                fontSize={'sm'}
                colorScheme='yellow'
                fontWeight={600}>
                Cadastro
              </Button>
            </Link>
            <ColorModeToggle />
          </HStack>
        </HStack>
      </Container>
    </chakra.header>
  )
}

export default Navbar